import { stringify } from 'qs'
import { betaFlag } from './flags'
const url = (path, queryParams) => {
  const query = queryParams ? `?${stringify(queryParams)}` : ''

  return `https://${process.env.API_HOSTNAME ||
    'api.emoji-camera.com'}/${path}${query}`
}

const API = {
  async getSkus() {
    const response = await fetch(url('skus'))
    const skus = await response.json()
    if (skus.success) {
      return skus.data
    }
    throw new Error('API Response Error')
  },
  async saveSnapshot(body) {
    const response = await fetch(url('snapshot'), {
      method: 'post',
      body: JSON.stringify(body)
    })

    const resp = await response.json()
  },
  async createPurchase(body) {
    const response = await fetch(url('purchase'), {
      method: 'post',
      body: JSON.stringify(body)
    })

    const resp = await response.json()
    if (resp.success) {
      return {
        sessionId: resp.sessionId,
        purchase: resp.purchase,
        purchaseId: resp.purchaseId
      }
    } else {
      throw new Error('Could not create checkout session')
    }
  },
  async getMockup({ snapshotId, productId, userId, skuId }) {
    const response = await fetch(
      url('mockup', {
        s: snapshotId,
        p: productId,
        u: userId,
        sk: skuId
      })
    )

    const resp = await response.json()
    if (resp.success) {
      return {
        status: resp.status,
        mockups: resp.mockups
      }
    } else {
      console.warn(resp)
      return null
    }
  },
  async getPurchase({ purchaseId, productId, skuId, userId }) {
    console.log(
      url('get purchase', {
        purchaseId,
        productId,
        skuId,
        userId
      })
    )
    const response = await fetch(
      url('purchase', {
        purchaseId,
        productId,
        skuId,
        userId
      })
    )

    const resp = await response.json()
    if (resp.success) {
      return {
        purchaseId,
        downloadSlug: resp.downloadSlug,
        mockups: resp.mockups,
        shippingStatus: resp.shippingStatus
      }
    } else {
      return null
    }
  },
  async getProducts() {
    const response = await fetch(url('products'), {
      method: 'get',
      headers: {
        ecbf: betaFlag() && '1'
      }
    })

    const resp = await response.json()
    if (resp.success) {
      return {
        status: resp.status,
        products: resp.data
      }
    } else {
      return null
    }
  }
}

export default API
