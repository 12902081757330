import html from 'choo/html'
import Component from 'choo/component'
import IconButton from './icon-button'

class Slider extends Component {
  constructor(props) {
    super()

    this.onTouchStart = this.onTouchStart.bind(this)
    this.onTouchEnd = this.onTouchEnd.bind(this)
    this.onTouchMove = this.onTouchMove.bind(this)

    this.iconButton = new IconButton(props.iconEmoji, {
      onTouchStart: this.onTouchStart,
      onTouchEnd: this.onTouchEnd,
      onTouchMove: this.onTouchMove
    })

    this.side = props.side
    this.name = props.name
  }

  update(state) {
    return true
  }

  createElement(state, emit) {
    this.emit = emit

    const classes = `slider ${this.side}-slider ${
      state.isActive ? 'is-active' : ''
    }`

    const button = html`
      <div class="slider-button-container">
        ${this.iconButton.render()}
      </div>
    `

    button.style.transform = `translateY(calc(-50% + ${-400 * state.value}px))`

    // <div class="slider-name label">${this.name}</div>

    return html`
      <div
        class="${classes}"
        onmousedown=${this.onTouchStart}
        onmouseup=${this.onTouchEnd}
        onmousemove=${this.onTouchMove}
        ontouchmove=${this.onTouchMove}
        ontouchstart=${this.onTouchStart}
        ontouchend=${this.onTouchEnd}
      >
        <div class="slider-range"></div>
        ${button}
      </div>
    `
  }

  onTouchStart(e) {
    this.isTouching = true
    const boundingClientRect = this.element.getBoundingClientRect()
    this.touchYOffset = boundingClientRect.y
    this.touchYMax = boundingClientRect.height
    this.touchXMin = boundingClientRect.x
    this.touchXMax = boundingClientRect.width + this.touchXMin
    this.emit('control-activate', this.name)
  }
  onTouchEnd(e) {
    this.isTouching = false
    delete this.touchYOffset
    delete this.touchYMax
    delete this.touchXMin
    delete this.touchXMax
    this.emit('control-deactivate', this.name)
  }
  onTouchMove(e) {
    if (!this.isTouching) return
    e.preventDefault()

    if (e.changedTouches) {
      const touchY = e.changedTouches[0].clientY
      this.updateControl(touchY)
    } else {
      const mouseY = e.clientY
      this.updateControl(mouseY)

      // try to handle mousing out of the slider
      if (e.clientX < this.touchXMin || e.clientX > this.touchXMax) {
        this.onTouchEnd()
      }
    }
  }
  updateControl(touchY) {
    let newValue = 1 - (touchY - this.touchYOffset) / this.touchYMax
    newValue = Math.max(newValue, 0)
    newValue = Math.min(newValue, 1)
    // console.log(this.name, newValue)
    this.emit('control-change', this.name, newValue)
  }
}

export default Slider
