try {
  const url = new URL(location.href)
  if (url.searchParams.get('ecbf') === '1') {
    localStorage.setItem('ecbf', '1')
  }
} catch (e) {
  console.error(e)
  console.error('error setting flags')
}

export const betaFlag = () => {
  return localStorage.getItem('ecbf') === '1'
}
