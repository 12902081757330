import html from 'choo/html'
import Component from 'choo/component'
import kebabCase from 'lodash/kebabCase'

class MerchItemGrid extends Component {
  constructor() {
    super()
    this.onClick = this.onClick.bind(this)
  }

  update() {
    return true
  }

  onClick() {}

  renderItem(props) {
    console.log('render merch item', props)
    const {
      snapshotSlug,
      product: {
        minPrice,
        maxPrice,
        metadata: {
          store_image: itemImageSrc,
          store_slug: itemSlug,
          store_name: itemName,
          store_options: itemOptions
        }
      }
    } = props

    let itemPrice
    if (minPrice === maxPrice) {
      itemPrice = `${minPrice / 100} USD`
    } else {
      itemPrice = `${minPrice / 100}+ USD`
    }

    return html`
      <a class="merch-item" href="/gallery/${snapshotSlug}/merch/${itemSlug}">
        <figure>
          <img class="merch-item-image" src="${itemImageSrc}" />
          <figcaption class="merch-item-info">
            <div class="merch-item-name">${itemName}</div>
            <div class="merch-item-price">${itemPrice}</div>
            <div class="merch-item-options">${itemOptions}</div>
          </figcation>
        </figure>
      </a>
    `
  }

  createElement(props) {
    const { products, snapshotSlug } = props

    if (products === null)
      return html`
        <div class="merch-grid"></div>
      `
    if (products.length === 0)
      return html`
        <div class="merch-grid"></div>
      `
    const merchItems = products.map(product => {
      return this.renderItem({
        snapshotSlug,
        product
      })
    })

    return html`
      <div class="merch-grid">${merchItems}</div>
    `
  }
}

export default MerchItemGrid
